import React from 'react';
import ReactDOM from 'react-dom';
import './style/Style.css';
import MyLogo from './style/logo-2.svg';

const App = () => {
    return (
        <div>
            <div class="logo-holder">
                <div class="corso-logo">
                    <img src={MyLogo} alt="Corso Creative logo"/>
                    Website coming soon.
                </div>
            </div>
            <div class="footer">
                For more information please email <a href="mailto:kristin@corsocreative.com">kristin@corsocreative.com</a>
            </div> 
        </div>
        
    );
}

ReactDOM.render(<App />, document.querySelector('#root'));